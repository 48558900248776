<template>
  <base-layout>
    <add-role />
    <content-section :spacing="false">
      <div class="px-4 content-spacing">
        <title-plus
          :title="$t('components.rolesManagement.headline')"
          @plus="add"
          class="mb-10"
        />
      </div>
      <template>
        <FSTable
          :fst-id="fstId"
          :endpoint="indexDataEndpoint"
          :headers="getTableHeaders"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" textFallbackAlways>
                  <FSTableRowItem>
                    <p
                      class="text-blue-500 cursor-pointer"
                      @click.prevent="edit(item)"
                    >
                      {{ item.role_name }}
                    </p>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    {{ item.created_by ? item.created_by.full_name : '--' }}
                    -
                    {{ item.created_by ? item.created_by.role_name : '--' }}
                  </FSTableRowItem>
                  <FSTableRowItem>
                    {{
                      getFormattedDateTime(
                        item.created_at,
                        'D MMM YYYY, h:mm:ss a'
                      )
                    }}
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <p
                      class="text-blue-500 cursor-pointer"
                      @click.prevent="edit(item)"
                    >
                      {{ item.role_name }}
                    </p>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    {{ item.created_by ? item.created_by.full_name : '--' }}
                    -
                    {{ item.created_by ? item.created_by.role_name : '--' }}
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div class="flex items-center">
                      <oto-edit-icon @click="edit(item)" />
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.rolesManagement.table.columns.createdAt'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.created_at,
                            'D MMM YYYY, h:mm:ss a'
                          )
                        }}
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { getFormattedDateTime } from '@/utils/datetime'
import AddRole from './RoleAddEdit'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { EventBus } from '@/utils'
import { RoleConfig } from '@/config/RoleConfig'
import { useEndpoints } from '@/composables'
export default {
  name: 'Roles',
  components: {
    BaseLayout,
    ContentSection,
    OtoEditIcon,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    AddRole,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      opened: [],
      fstId: 'RolesIndex',
      indexDataEndpoint: useEndpoints.role.index(),
      tableHeaders: [
        {
          text: `${this.$t('components.rolesManagement.table.columns.Name')}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rolesManagement.table.columns.createdBy'
          )}`,
          width: '25%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rolesManagement.table.columns.createdAt'
          )}`,
          width: '25%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rolesManagement.table.columns.actions'
          )}`,
          width: '20%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t('components.rolesManagement.table.columns.Name')}`,
          width: '30%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rolesManagement.table.columns.createdBy'
          )}`,
          width: '30%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.rolesManagement.table.columns.actions'
          )}`,
          width: '30%',
          sort: null,
        },
      ],
    }
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add() {
      EventBus.$emit(RoleConfig.events.editingData, {})
      dispatchEvent(new Event(RoleConfig.events.sorToggle))
    },
    edit(item) {
      EventBus.$emit(RoleConfig.events.editingData, item)
      dispatchEvent(new Event(RoleConfig.events.sorToggle))
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
