var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"roles"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Edit' : 'Add')+" Roles and Permissions ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex items-center justify-end mt-6"},[_c('t-button',{attrs:{"variant":"secondary"},on:{"click":_vm.dismiss}},[_vm._v("Cancel")]),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit","disabled":_vm.form.role_name === 'Owner'},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save")])],1)]},proxy:true}])},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"block px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',{staticClass:"sticky top-0 left-0 z-10"},[_c('div',{staticClass:"flex flex-wrap w-full"},[_c('text-input',{attrs:{"rules":"required","label":"Role name"},model:{value:(_vm.form.role_name),callback:function ($$v) {_vm.$set(_vm.form, "role_name", $$v)},expression:"form.role_name"}})],1),_c('fsTableContainer',[_c('fsTable',[_c('fsTableHead',_vm._l((_vm.tableHeaders),function(item,index){return _c('fsTableHeadItem',{key:("fs-table-header-" + index),attrs:{"item":item,"type":"permission","className":item.className}})}),1)],1)],1)],1),_c('div',{staticClass:"grid grid-cols-1 mt-5 "},[_c('fsTableContainer',[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('fsTable',_vm._l((_vm.permissions),function(item,index){return _c('fsTableRow',{key:("fs-table-row-" + index)},[_c('fsTableRowItem',{attrs:{"type":"permission","className":"text-left"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_vm._l((_vm.actionTypes),function(actionType,actionTypeIndex){return _c('fsTableRowItem',{key:("action-type-row-" + (item.action_types.includes(
                    actionType
                  )) + "-" + index + "-" + actionTypeIndex),attrs:{"type":"permission"}},[(
                    (_vm.availableActionsMap[item.name] || []).includes(
                      actionType
                    ) && actionType === 'VIEW'
                  )?_c('t-toggle',{attrs:{"checked":item.action_types.includes(actionType) ? true : false,"variant":"success"},on:{"change":function($event){return _vm.onChangeToggle(index, actionType)}}}):(
                    (_vm.availableActionsMap[item.name] || []).includes(
                      actionType
                    ) && actionType !== 'VIEW'
                  )?_c('t-toggle',{attrs:{"checked":item.action_types.includes(actionType) ? true : false,"disabled":_vm.permissions[index].action_types.includes('VIEW')
                      ? false
                      : true,"variant":"success"},on:{"change":function($event){return _vm.onChangeToggle(index, actionType)}}}):_c('t-toggle',{attrs:{"checked":item.action_types.includes(actionType) ? true : false,"disabled":!(_vm.availableActionsMap[item.name] || []).includes(
                      actionType
                    ),"variant":"success"}})],1)})],2)}),1)],1)],1),_c('input',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit","value":"Submit"}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }